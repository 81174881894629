import { useEffect, useContext, useState, useRef } from 'react'
import { styled } from '@mui/system'
import { ButtonGroup, Button } from '@mui/material'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'

import BooleanPossibleAnswer from './BooleanPossibleAnswer'
import FreeFlowPossibleAnswer from './FreeFlowPossibleAnswer'
import SimpleTextPossibleAnswer from './SimpleTextPossibleAnswer'
import CheckboxPossibleAnswer from './CheckboxPossibleAnswer'
import OptOutLinkPossibleAnswer from './OptOutLinkPossibleAnswer'
import EmojiScalePossibleAnswer from './EmojiScalePossibleAnswer'
import MultipleChoicePossibleAnswer from './MultipleChoicePossibleAnswer'
import NpsPossibleAnswer from './NpsPossibleAnswer'

import { SurveyContext } from './surveyContext'
import * as Survey from './surveyHelpers'
import { capitalizeFirstLetter } from '../common/helpers'

import {
  AnswersContainer,
  SurveyActions,
  QuestionContainerParent,
} from '@talkadot/survey-component-library'

const NavButtonContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '0',
  right: '0',
  padding: '1.25rem 1.25rem',
  zIndex: '100',
  svg: {
    fontSize: '1.5rem',
    color: theme.palette.base.white,
  },
}))

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  boxShadow: theme.shape.boxShadow.xs,
  borderRadius: theme.shape.borderRadius.sm,
}))

const QuestionParent = () => {
  const [questionContent, setQuestionContent] = useState('')
  const requiredTemplateRefs = useRef([])
  const {
    surveyState,
    submitNonBooleanAnswer,
    goToPreviousQuestion,
    goToNextQuestion,
    setFirstQuestionId,
  } = useContext(SurveyContext)
  const {
    isLoading,
    // speaker,
    customQuestion,
    configurationOptions,
    surveyType,
  } = surveyState

  const question = Survey.findCurrentQuestion(surveyState)
  const skippedCodeQuestion =
    question?.questionOrder === 0 && surveyState.codeInUrl
  const skippedCodeAndLeadgenQuestion =
    surveyState.firstQuestionId === question?.id && surveyState.codeInUrl

  useEffect(() => {
    // NOTE: Only customizable surveys have disabled questions
    if (question?.enabled === false) {
      goToNextQuestion({ currentQuestionOrder: question.questionOrder })
      return
    }

    // If the first question is a leadgen question, and leadgen is disabled, skip it
    if (configurationOptions?.disableLeadgen && question?.isLeadgenQuestion) {
      setFirstQuestionId(question.skipQuestionId)
      // NOTE: This is a little hacky, we're telling it to go to the same question, and then letting the goToNextQuestion logic figure out the next question
      goToNextQuestion({ nextQuestionId: question.id })
      return
    }

    // Check if we skipped the code question
    if (skippedCodeQuestion && !surveyState.customQuestionActive) {
      let content = `
      <h1>Hi! &#128075; Thanks for your time!</h1>
        <br />
        <br />
      `

      if (surveyType === 'customizable') {
        content += `<div>${question.content}</div>`
      } else {
        content += `<div>Let's start with your contact info<div>`
      }

      setQuestionContent(content)
      // Check if the code was in the url and we skipped the first question because of leadgen settings
    } else if (
      skippedCodeAndLeadgenQuestion &&
      !surveyState.customQuestionActive
    ) {
      setQuestionContent(
        '<h1>Hi! &#128075; Thanks for your time!</h1><br /><br /><br />' +
          question.content
      )
    } else {
      setQuestionContent(question.content)
    }
  }, [question])

  const hasCustomQuestion = () => {
    return customQuestion && customQuestion.question
  }

  const renderAnswers = () => {
    const possibleAnswers = Survey.findPossibleAnswersByQuestion(
      surveyState,
      question
    )

    if (!possibleAnswers) {
      return
    } else {
      const sortedPossibleAnswers = possibleAnswers.sort(
        (a, b) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder)
      )

      return sortedPossibleAnswers.map((possibleAnswer, i) => {
        return renderAnswer({ possibleAnswer, index: i })
      })
    }
  }

  const renderAnswer = ({ possibleAnswer, index }) => {
    switch (possibleAnswer.answerType) {
      case Survey.ANSWER_TYPES.EMAIL:
      case Survey.ANSWER_TYPES.NAME:
      case Survey.ANSWER_TYPES.JOB_TITLE:
      case Survey.ANSWER_TYPES.ORGANIZATION:
      case Survey.ANSWER_TYPES.PHONE:
        return (
          <SimpleTextPossibleAnswer
            ref={(el) => (requiredTemplateRefs.current[index] = el)}
            key={index}
            possibleAnswer={possibleAnswer}
          />
        )
      case Survey.ANSWER_TYPES.BOOLEAN:
        return (
          <BooleanPossibleAnswer key={index} possibleAnswer={possibleAnswer} />
        )
      case Survey.ANSWER_TYPES.MULTIPLE_CHOICE:
        return (
          <MultipleChoicePossibleAnswer
            key={index}
            possibleAnswer={possibleAnswer}
          />
        )
      case Survey.ANSWER_TYPES.FREE_FLOW:
        return (
          <FreeFlowPossibleAnswer key={index} possibleAnswer={possibleAnswer} />
        )
      case Survey.ANSWER_TYPES.OPPORTUNITY_DETAILS:
        return (
          <FreeFlowPossibleAnswer key={index} possibleAnswer={possibleAnswer} />
        )
      case Survey.ANSWER_TYPES.CHECKBOX:
        return (
          <CheckboxPossibleAnswer key={index} possibleAnswer={possibleAnswer} />
        )
      case Survey.ANSWER_TYPES.OPT_OUT_LINK:
        return (
          <OptOutLinkPossibleAnswer
            key={index}
            possibleAnswer={possibleAnswer}
          />
        )
      case Survey.ANSWER_TYPES.EMOJI_SCALE:
        return (
          <EmojiScalePossibleAnswer
            key={index}
            possibleAnswer={possibleAnswer}
          />
        )
      case Survey.ANSWER_TYPES.NPS:
        return <NpsPossibleAnswer key={index} possibleAnswer={possibleAnswer} />
      default:
        return <div />
    }
  }

  const shouldRenderActions =
    !isLoading &&
    question.questionType !== Survey.QUESTION_TYPES.BOOLEAN &&
    question.questionType !== Survey.QUESTION_TYPES.MULTIPLE_CHOICE &&
    question.questionType !== Survey.QUESTION_TYPES.EMOJI_SCALE &&
    question.questionType !== Survey.QUESTION_TYPES.NPS

  const shouldRenderNavButtons = () =>
    (question?.id && surveyState.previousQuestionIds.length > 0) ||
    surveyState.customQuestionActive

  const findSelectedPossibleAnswer = () => {
    // need this check because free flow questions will have an answerValue as soon as the user types something
    if (!surveyState.submittedQuestionIds.includes(question.id)) {
      return null
    }

    const possibleAnswers = Survey.findPossibleAnswersByQuestion(
      surveyState,
      question
    )

    return possibleAnswers.find((possibleAnswer) => possibleAnswer.answerValue)
  }

  const handleNavNextQuestionClick = () => {
    // if the current question has previously been answered, go to the next question
    const selectedPossibleAnswer = findSelectedPossibleAnswer()

    if (!selectedPossibleAnswer) {
      return
    }

    goToNextQuestion({
      nextQuestionId: selectedPossibleAnswer.nextQuestionId,
      previousQuestionId: question.id,
    })
  }

  const renderNavButtons = () =>
    shouldRenderNavButtons() && (
      <NavButtonContainer>
        <StyledButtonGroup
          disableElevation
          variant="contained"
          color="nav"
          aria-label="Survey navigation buttons">
          <Button onClick={goToPreviousQuestion}>
            <IoChevronBackOutline />
          </Button>
          <Button
            onClick={handleNavNextQuestionClick}
            disabled={!findSelectedPossibleAnswer()}>
            <IoChevronForwardOutline />
          </Button>
        </StyledButtonGroup>
      </NavButtonContainer>
    )

  const subContent = Survey.checkSubContent(
    question?.subContent,
    hasCustomQuestion()
  )

  const requiredAnswersValid = () => {
    let allValid = true

    requiredTemplateRefs.current
      .filter((ref) => ref !== null)
      .forEach((ref) => {
        const inputRequirementSatisfied = ref.forceValidation()
        if (!inputRequirementSatisfied) {
          allValid = false
        }
      })

    return allValid
  }

  const handleSubmit = async () => {
    // inputs will display errors to correct
    if (requiredAnswersValid()) {
      submitNonBooleanAnswer()
    }
  }

  const contentAlign = () => {
    // NOTE: this conditional styling relies on isBookingFlowQuestion being set on the question object
    if (
      question?.questionType === Survey.ANSWER_TYPES.BOOLEAN ||
      (question?.questionType === Survey.ANSWER_TYPES.MULTI_INPUT &&
        !question?.isBookingFlowQuestion)
    ) {
      return 'center'
    }

    return 'left'
  }

  return (
    <QuestionContainerParent
      content={capitalizeFirstLetter(questionContent)}
      subContent={subContent}
      addExtraBottomPadding={
        question.questionOrder > 0 || surveyState.customQuestionActive
      }
      reduceMargins={skippedCodeQuestion}
      contentAlign={contentAlign()}>
      <AnswersContainer
        questionType={question?.questionType}
        isLoading={isLoading}
        renderAnswers={renderAnswers}
      />
      <SurveyActions
        shouldRenderActions={shouldRenderActions}
        handleClick={handleSubmit}
        actionText={question.questionOrder === 0 ? "Let's Go!" : 'Next'}
      />
      {renderNavButtons()}
    </QuestionContainerParent>
  )
}

export default QuestionParent
