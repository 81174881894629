import { useContext } from 'react'
import { SurveyContext } from './surveyContext'
import { NpsPossibleAnswerTemplate } from '@talkadot/survey-component-library'

const NpsPossibleAnswer = ({ possibleAnswer }) => {
  const { submitEmojiScaleAnswer } = useContext(SurveyContext)

  const handleClick = (value) => {
    submitEmojiScaleAnswer(possibleAnswer, value)
  }

  return (
    <NpsPossibleAnswerTemplate
      handleClick={handleClick}
      answerValue={possibleAnswer?.answerValue}
    />
  )
}

export default NpsPossibleAnswer
